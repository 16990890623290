import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { client } from '../client'
import { checkPassword } from '../../util/utilFunctions'

const CreateAccount = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userName, setUserName] = useState('');
    const [userNameExists, setUserNameExists] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordOpacity, setPasswordOpacity] = useState(0);
    const [error, setError] = useState('')

    const navigate = useNavigate();
    const maxPasswordStrength = 4;

    const checkUsername = (userName: string) => {
        if (userName.length >= 255) {
            setError('username is too long')
            return false
        } else {
            setError('')
            return true
        }
    }

    const lookupUsername = (userName: string) => {
        client.get('/api/auth/existing-user', { params: { userName } })
            .then(res => {
                // set loader and success message?
                if (res.data.message === "userName ok") return setUserNameExists(false);
                else return setUserNameExists(true);
            })
            .catch(err => {
                if(err.response.status === 409) setUserNameExists(true);
            });
    }

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let strength = 0;

        const localPassword = e.target.value;

        const hasUpperCase = /[A-Z]/.test(localPassword);
        const hasLowerCase = /[a-z]/.test(localPassword);
        const hasNumbers = /\d/.test(localPassword);
        const hasNonalphas = /[^\w\s*]+/.test(localPassword);

        if (hasUpperCase) strength++;
        if (hasLowerCase) strength++;
        if (hasNumbers) strength++;
        if (hasNonalphas) strength++;

        setPasswordStrength(strength);

        setPassword(localPassword)
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (firstName.length < 2) return setError("First name length is too short");
        if (lastName.length < 2) return setError("Last name length is too short");
        if (password !== confirmPassword) return setError('Passwords and confirm password must match');
        if (checkUsername(userName) && checkPassword(password, setError, undefined)) setError('');
        else return

        client.post('/api/auth/create-account', { firstName, lastName, email, userName, password })
            .then(res => {
                // set loader and success message?
                if (res.data.message === "success") navigate("/login");
            })
            .catch(err => {
                if (err?.response.status === 500) return alert("Server error: There is something wrong on our side. Please provide some time to restore the servers")

                return alert("There was an error proccessing your request")
            })
    }

    return (
        <main>
            <div className="auth flex-center">
                <div className={"form-container create-account"}>
                    <form onSubmit={onSubmit} className={"auth-form"}>
                        {/* <img alt="logo" src={logo} /> */}
                        <h1 className={"auth-header"}>Create your Account</h1>
                        <div className="input-container">
                            <label className="required">Email</label>
                            <input
                                required
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="multi-input">
                            <div className="input-container">
                                <label className="required">First Name</label>
                                <input
                                    className={error.includes("First name") ? "error-field" : ""}
                                    onBlur={() => setError("")}
                                    required
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                                <small className={error.includes("First name") ? "error-info" : "hidden"}>must be 2+ chars</small>
                            </div>
                            <div className="input-container">
                                <label className="required">Last Name</label>
                                <input
                                    className={error.includes("Last name") ? "error-field" : ""}
                                    onBlur={() => setError("")}
                                    required
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                                <small className={error.includes("Last name") ? "error-info" : "hidden"}>must be 2+ chars</small>
                            </div>
                        </div>
                        <div className="input-container">
                            <label className="required">Username</label>
                            <input
                                className={userNameExists ? "error-field" : ""}
                                onBlur={() => lookupUsername(userName)}
                                required
                                value={userName}
                                onChange={e => setUserName(e.target.value)}
                            />
                            <small className={userNameExists ? "error-info" : "hidden"}>username already exists</small>
                        </div>
                        <div className="multi-input">
                            <div className="input-container">
                                <label className="required">Password</label>
                                <input
                                    required
                                    value={password}
                                    type="password"
                                    onChange={e => onPasswordChange(e)}
                                    onFocus={() => setPasswordOpacity(1)}
                                    onBlur={() => setPasswordOpacity(0)}
                                />
                                <progress
                                    className={`progress-${passwordStrength}`}
                                    style={{ opacity: passwordOpacity }}
                                    value={passwordStrength}
                                    max={maxPasswordStrength} ></progress>
                            </div>
                            <div className="input-container">
                                <label className="required">Confirm Password</label>
                                <input
                                    className={error.includes("password") ? "error-field" : ""}
                                    required
                                    value={confirmPassword}
                                    type="password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                <small className={error === "Passwords and confirm password must match" ? "error-info" : "hidden"}>passwords do not match</small>
                                <small className={error === "password is too short" ? "error-info" : "hidden"}>Password must be 7+ chars</small>
                            </div>
                        </div>
                        <button className="main-button" type="submit">Create Account</button>
                        <span>Already a user? <Link className="link" to="/login">Sign in instead</Link></span>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default CreateAccount