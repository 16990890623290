import { useState } from "react";
import { motion } from "framer-motion";
import LeftArrowSVG from "../svgs/LeftArrowSVG";
import RightArrowSVG from "../svgs/RightArrowSVG";
import DotSVG from "../svgs/DotSVG";

export type CarouselProps = {
    className?: string
    children: Array<any>
}

const Carousel: React.FC<CarouselProps> = ({ className = "ts-carousel", children }) => {
    const [activeItem, setActiveItem] = useState(children[0]);
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance

        if(isLeftSwipe) return onChildChange("forward", activeItem, null);
        else if(isRightSwipe) return onChildChange("back", activeItem, null);
        // add your conditional logic here
    }

    const onChildChange = (progress: string, currentSelection: any, position: number | null) => {
        if (position !== null) {
            return setActiveItem(children[position]);
        }

        if (progress === "forward") {
            const current = children.map(c => c.title).indexOf(currentSelection.title);

            if (current + 1 !== children.length) {
                setActiveItem(children[current + 1]);
            } else if (current + 1 === children.length) setActiveItem(children[0]);
        } else {
            const current = children.map(c => c.title).indexOf(currentSelection.title);

            if (current === 0) {
                setActiveItem(children[children.length - 1]);
            } else setActiveItem(children[current - 1]);
        }
    }

    return (
        <div className={className}>
            {children.map((child, i) => (
                <motion.div 
                initial={{ opacity: 0 }} 
                animate={{ opacity: activeItem.title === child.title ? 1 : 0 }} 
                key={i} 
                className={`${className}_item`}
                onTouchStart={onTouchStart} 
                onTouchMove={onTouchMove} 
                onTouchEnd={onTouchEnd}>
                    <div className={`${className}_itemleft`}>
                    <h5>We Offer</h5>
                    <h4>{child.title}</h4>
                    <p>{child.description}</p>
                    </div>
                    <div className={`${className}_itemright flex-center`}>
                        <img src={child.image} alt={child.title} />
                    </div>
                </motion.div>
            ))}
            <div className={`${className}_position`}>
                {children.map((b, i) => <DotSVG className={activeItem.title === b.title ? "active" : ""} onClick={() => onChildChange("", null, i)} key={i} />)}
            </div>
            <LeftArrowSVG className={`${className}_back`} onClick={() => onChildChange("back", activeItem, null)} />
            <RightArrowSVG className={`${className}_forward`} onClick={() => onChildChange("forward", activeItem, null)} />
            {/* <button >back</button>
            <button >forward</button> */}
        </div>
    )
}

export default Carousel;