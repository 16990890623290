import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./assets/css/header.scss"
import "./assets/css/main.scss"
import ErrorBoundary from "./components/ErrorBoundary"
import RootElement from "./components/RootElement"
// import Admin from "./AdminPage"
import Login from "./components/auth/Login"
import CreateAccount from "./components/auth/CreateAccount"
import RequireAuthElement from "./components/auth/RequireAuthElement"
import ForgotPasswordPage from "./components/auth/ForgotPasswordPage"
import ResetPasswordPage from "./components/auth/ResetPasswordPage"
import HomePage from "./components/HomePage"
import ContactPage from "./components/ContactPage"
import PrivacyPolicyPage from "./components/Legal/PrivacyPolicyPage"
import TermsPage from "./components/Legal/TermsPage"
import WorkPage from "./components/WorkPage"

const router = createBrowserRouter([
    {
        errorElement: <ErrorBoundary />,
        element: <RootElement />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/contact",
                element: <ContactPage />
            },
            {
                path: "/work",
                element: <WorkPage />
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/create-account",
                element: <CreateAccount />
            },
            {
                path: "/forgot-password",
                element: <ForgotPasswordPage />
            },
            {
                path: "/reset-password/:passwordResetId",
                element: <ResetPasswordPage />
            },
            {
                path: "/privacy",
                element: <PrivacyPolicyPage />
            },
            {
                path: "/terms",
                element: <TermsPage />
            }
        ]
    },
    {
        errorElement: <ErrorBoundary />,
        element: <RequireAuthElement />,
        children: [
            // {
            //     path: "/admin",
            //     element: <Admin />
            // }
        ]
    },
])

const App = () => {
    
    return (
        <RouterProvider router={router}></RouterProvider>
    )
}

export default App