import React, { useState, useContext } from 'react'
import { client } from '../client'
import { Link, useNavigate, useLocation } from 'react-router-dom'
// import logo from '../../assets/imgs/logo.jpg'
import CircularProgress from '../CircularProgress/CircularProgress'
import { useAuth } from '../../hooks/useAuth'
import { websiteName } from '../../util/constants'
import { checkPassword } from '../../util/utilFunctions'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);

    const { signIn } = useAuth();

    let location = useLocation();
    let navigate = useNavigate();

    const checkEmail = () => {
        if (email.length >= 50) {
            setError('email is too long');
            const input = document.querySelector("#login-email") as HTMLElement;
            input.focus();

            return false;
        } else {
            setError('');
            return true;
        }
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const passwordEl = document.querySelector("#login-password") as HTMLElement;

        if (checkEmail() && checkPassword(password, setError, passwordEl)) setError('')
        else return

        setLoggingIn(true)

        client.post('/api/auth/login', { email: email, password: password })
            .then(res => {
                if (res.status === 200) {
                    signIn({ ...res.data.data, authorized: true })
                    navigate("/lobby", { state: location, replace: true })
                }
            })
            .catch(err => {
                if (err?.response === undefined || err.response.status === 500) return alert("Server error: There is something wrong on our side. Please provide some time to restore the servers")
                
                if (err?.response.data.message.includes("Possibly missing login information") 
                    || err?.response.data.message.includes("Incorrect login information")) {
                    return setError("Wrong email or password. Try again or create an account.")
                }

                return setError("There was an error proccessing your request")
            })
            .finally(() => {
                setLoggingIn(false)
            })
    }

    const renderLoginScreenContent = (loggingIn: boolean) => {
        if (loggingIn) {
            return <CircularProgress />
        } else {
            return (
                <>
                    <div className="input-container">
                        <label className="required">Email</label>
                        <input
                            placeholder="example@mail.com"
                            required
                            id="login-email"
                            type="email"
                            value={email}
                            onChange={e => {
                                if (error) setError("");
                                setEmail(e.target.value)
                            }}
                        />
                    </div>
                    <div className="input-container">
                        <label className="required">Password</label>
                        <input
                            placeholder="password"
                            required
                            id="login-password"
                            value={password}
                            type="password"
                            onChange={e => {
                                if (error) setError("");
                                setPassword(e.target.value)
                            }}
                        />
                        <Link className="link" to="/forgot-password">Forgot Password?</Link>
                    </div>
                </>
            )
        }
    }

    return (
        <main>
            <div className="auth flex-center">
                <div className="form-container mini">
                    <form onSubmit={onSubmit} className="auth-form">
                        {!!error ? <p className="error-output">{error}</p> : ''}
                        {/* <img alt="logo" src={logo} /> */}
                        <h1 className="auth-header" style={{ fontSize: '2.4rem', fontWeight: 'bold' }}>Login</h1>
                        {renderLoginScreenContent(loggingIn)}
                        <button className="main-button" type="submit">Log in</button>
                        <span>New to {websiteName}? <Link className="link" to="/create-account">Sign up</Link></span>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Login