import { DefaultElementProps } from "../../util/customTypes";

const LeftArrowSVG: React.FC<DefaultElementProps> = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={ onClick !== null ? () => onClick() : () => {}} width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12.7071 7.29289C13.0976 7.68342 13.0976 8.31658 12.7071 8.70711L10.4142 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H10.4142L12.7071 15.2929C13.0976 15.6834 13.0976 16.3166 12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289Z"/>
</svg>
    )
}

export default LeftArrowSVG;