import axios from "axios";
import { removeUser } from "../auth/configureAuth";

export const getQueryString = (data = {}) => {
    return Object.entries(data)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
}

export const client = axios.create({
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    timeout: 1000,
    withCredentials: true,
    transformRequest: getQueryString
})

export const JSONClient = axios.create({
    headers: {'Content-Type': 'application/json'},
    timeout: 1000,
    withCredentials: true
})

client.interceptors.response.use(function(response) { return response}, function(error) {
    const errorObject = error.toJSON()
    if(errorObject.status === 403) {
        // logs out users and redirects to login
        removeUser("loggedIn")
        window.location.assign("/login")
        return
    }

    return Promise.reject(error)
})