const TermsPage = () => {
    const websiteNameEx = "Descifle Consultancy";
    const websiteAddress = "descifle";
    const companyName = "Descifle LLC";

    return (
        <main>
            <section>
                <p><strong><br /></strong></p>
                <p><strong>1</strong><strong>. ACCEPTANCE OF TERMS</strong></p>
                <p>By accessing, downloading, installing, or using the {websiteNameEx} website, whether or not you become a registered user (“User”, “You”, “Yourself”, “Your”), You agree to be bound by these Terms, which You acknowledge that You have read and understood.</p>
                <p>We reserve the right, at Our sole discretion, to change, modify or otherwise alter these Terms at any time. You must review these Terms at https://www.{websiteAddress}.com/terms on a regular basis to keep Yourself informed of any changes.</p>
                <p><strong>2. EARNINGS AND INCOME DISCLAIMER:</strong><br />{companyName} (“{companyName}”) 
                can not and does not make any guarantees about your ability to get results or earn any money with our ideas, 
                information, tools, or strategies. What we can guarantee is your satisfaction with our training. 
                We give you a 30-day 100% satisfaction guarantee on all non-subscription products we sell, 
                so if you are not happy for any reason with the quality of our training, just ask for your money back. 
                You should know that all products and services by our company are for educational and informational purposes only. 
                Nothing on this page, any of our websites, or any of our content or curriculum is a promise or guarantee of results or 
                future earnings, and we do not offer any legal, medical, tax or other professional advice. Any financial numbers referenced here, 
                or on any of our sites, are illustrative of concepts only and should not be considered average earnings, exact earnings, 
                or promises for actual or future performance. Use caution and always consult your accountant, 
                lawyer or professional advisor before acting on this or any information related to a lifestyle change or your business or finances. 
                You alone are responsible and accountable for your decisions, actions and results in life, 
                and by using our services you agree not to attempt to hold us liable for your decisions, actions or results, 
                at any time, under any circumstance.</p>
                <p><strong>Hours of Operation</strong></p>
                <ul>
                    <li>{companyName} (“{companyName}”) corporate office is open Monday  Friday 9a.m. - 5p.m. CST</li>
                    <li>We close for all government observed holidays</li>
                </ul>
                <p>This policies and procedures applies to&nbsp;all {websiteNameEx} products sold on this site or any other site owned by {websiteNameEx} that includes a link to this page.</p>
                <p>{websiteNameEx} &nbsp;<a href={`https://www.${websiteAddress}.com/`}>www.{websiteAddress}.com</a></p>
                <p><strong>Billing Address</strong></p>
                <ul>
                    <li>Billing address must be the same as the shipping address. Due to credit card procedures, we cannot ship to a different address than we bill to.</li>
                </ul>
                <p><strong>3. Returns and Cancellations<br /></strong></p>
                {/* <p><strong>3.1 Physical Returns, Non-Subscription Products &amp; Shipping</strong></p>
                <p><strong>Returns &amp; Non- Subscription Cancellations</strong></p>
                <ul>
                    <li>100% money back guarantee covers price of product only. 100% money back guarantee does not cover shipping and handling fee due to the fact that the customer made the decision to try product, therefore the customer accepts to pay the fee to ship the product.</li>
                    <li>Customers must contact our support department for a RMA (Return Authorization Number). This number must be displayed on the outside of all return packages. Returned packages without RMA numbers will not be accepted.</li>
                    <li>Once a return is received or valid refund request submitted, the refund process will take no more than 30 days. All refunds will be either returned to the original credit card OR- mailed in check form to the billing name and address. International orders that will be refunded back to the original credit card, and PayPal orders will be credited back to the PayPal account.</li>
                    <li>You have&nbsp;<strong>THIRTY (30) Days</strong>&nbsp;from the date of the original purchase to return the product to receive your refund. Any return received after the&nbsp;<strong>THIRTY (30) DAY</strong>&nbsp;time limit will not be processed.</li>
                    <li>Shipping of the product from Infomastery, LLC to your door will not be refunded. Shipping of the product to the Infomastery, LLC Refund Department will not be compensated.</li>
                    <li>To ensure that your product is not lost or damaged, we strongly recommend sending the product certified mail with a tracking number. If we do not receive the product back and the customer does not have proof that the product was returned we cannot issue a refund.</li>
                    <li>Valid proof of returning a product includes: delivery confirmation or signature required via USPS. If the customer has delivery confirmation or signature required, then a full refund will be issued.</li>
                    <li>Due to loss or missing or slow mail, we will honor any package that is postmarked for up to one year after the purchase of the product.</li>
                </ul> */}
                <p><strong>3.1 Subscriptions, Trials and Digital Products</strong></p>
                {/* <p><strong>Free Trials:</strong>&nbsp;Your {websiteNameEx} membership may start with a free trial. The free trial period of your membership lasts for one month, or as otherwise specified during sign-up and is intended to allow new and certain former members to try the service.</p>
                <p><strong>Free trial eligibility</strong>: Eligibility is determined by {websiteNameEx} at its sole discretion and we may limit eligibility or duration to prevent free trial abuse. We reserve the right to revoke the free trial and put your account on hold in the event that we determine that you are not eligible. Members of households with an existing or recent {websiteNameEx} membership are not eligible. We may use information such as device ID, method of payment or an account email address used with an existing or recent {websiteNameEx} membership to determine eligibility. For combinations with other offers, restrictions may apply.</p>
                <p><strong>Trial Billing:</strong>&nbsp;We will charge your Payment Method for your monthly membership fee at the end of the free trial period and your membership will automatically renew monthly unless you cancel your membership prior to the end of the free trial period. To view the monthly membership price and end date of your free trial period, visit our website and click the “Billing details” link on the “Account” page.</p> */}
                <p><strong>Billing Cycle:</strong>&nbsp;The membership fee for the {websiteNameEx} service any other charges you may incur in 
                connection with your use of the service, such as taxes and possible transaction fees, 
                will be charged on a monthly basis to your Payment Method on the calendar day corresponding to the 
                commencement of the paying portion of your membership until your membership is cancelled. 
                Membership fees are fully earned upon payment. In some cases your payment date may change, 
                for example if your Payment Method has not successfully settled or if your paid membership began on a day not contained in a given month. 
                {/* Visit our website and click on the “Cancel Membership” link on the “My Profile” page to see your next payment date. 
                We may authorize your Payment Method in anticipation of membership or service-related charges through various methods, 
                including authorizing it up to approximately one month of service as soon as you register. In some instances, 
                your available balance or credit limit may be reduced to reflect the authorization during your free trial period. */}
                </p>
                <p><strong>Cancellation:</strong>&nbsp;You can cancel your {websiteNameEx} membership at any time, 
                and you will continue to have access to the {websiteNameEx} service through the end of your monthly billing period.
                &nbsp;<strong>We do not provide refunds or credits for any partial-month membership periods</strong>. 
                {/* To cancel, go to the “My Profile” page on our website, select ‘Cancel Membership’, and follow the instructions for cancellation.  */}
                To cancel, reach out to us at our service phone number 470-595-5674. 
                If you cancel your membership, your account will automatically close at the end of your current billing period. 
                To see when your account will close, click “Billing details” on the “Account” page.</p>
                <ul>
                    <li>Excessive returns will not be accepted. Refunds will not be given to any one customer for one specific product more than twice. There is an unlimited amount of orders that a customer can place for one specific product, but the money back guarantee is void after a customer has used it for one specific product more than twice.</li>
                    {/* <li>Shipping of the product from Infomastery, LLC to your door will not be refunded. Shipping of the product to the Infomastery, LLC Refund Department will not be compensated.</li>
                    <li>To ensure that your product is not lost or damaged, we strongly recommend sending the product certified mail with a tracking number. If we do not receive the product back and the customer does not have proof that the product was returned we cannot issue a refund.</li>
                    <li>Valid proof of returning a product includes: delivery confirmation or signature required via USPS. If the customer has delivery confirmation or signature required, then a full refund will be issued.</li>
                    <li>Due to loss or missing or slow mail, we will honor any package that is postmarked for up to one year after the purchase of the product.</li> */}
                </ul>
                <p><strong>4. Terms of Subscription Renewal</strong></p>
                {/* <p><strong>Trial</strong>&nbsp;members will receive a notice from us that your trial period is ending or that the paying portion of your membership has begun. We will continue to bill your Payment Method on a monthly basis for your membership fee until you cancel. To cancel your subscription submit a ticket at support@{websiteAddress}.com.</p> */}
                <p><strong>Monthly</strong>&nbsp;{websiteNameEx} Subscriptions automatically renew each month at the stated retail price until the subscription buyer notifies the company that they wish to cancel. Please refer to Returns &amp; Subscription Cancellations section of the Terms of Service page.</p>
                <p><strong>Yearly</strong>&nbsp;{websiteNameEx} Subscribers will be notified via email prior to renewal and the yearly subscription will automatically renew each year at the stated retail price until the subscription buyer notifies the company that they wish to cancel. Please refer to Returns &amp; Subscription Cancellations section of the Terms of Service page.</p>
                {/* <p><strong>5. {websiteNameEx} Certifications and Mastery Courses</strong></p>
                <p className="Body">You have THIRTY (30) Days from the date of the original purchase to review the training and preview the course. Any refund requests after the THIRTY (30) DAY time limit will not be processed.</p>
                <p className="Body">No refunds will be processed after the final exam has been started.</p>
                <p className="Body"><strong>5.1 CDMP Mastery Class<br /></strong></p>
                <p className="Body">You have THIRTY (30) Days from the start date of the class to request a refund. Any refund requests after the THIRTY (30) DAY time limit will not be processed.</p>
                <p className="Body">No refunds will be processed after the final exam has been started.</p>
                <p><strong>6. Lab Member Discount Policy</strong></p>
                <p className="Body">From time to time, on some {websiteNameEx} products, we will offer our Lab members the opportunity to purchase additional products and services at a discount rate. To be eligible for this discount, the customer must me a member in good standing at the time of purchase. No rebates or partial refunds will be offered if a customer purchases a training, and then joined DM Lab, because these discount are intended to be a benefit for active members, and not an enticement for new members.</p>
                <p className="Body">In the event that a Lab member purchases an item that was eligible for a discount, if the Lab member notifies our Customer Care department within 30 days of the purchase, a partial refund for the discounted amount will be offered. If the available discount is not reported within 30 days, a credit for additional purchases will be offered, but note that no refunds or credits will be offered after 12 months from the date of purchase.</p>
                <p className="Body"><strong>7. Live Events</strong></p>
                <p>The events, information, and speakers listed on our Sites are subject to change without notification.</p>
                <p>You have SIXTY (60) Days prior to the event to request a 50% refund of your ticket price or seat deposit. Any refund requests within THIRTY (30) DAYS of the event will not be processed. Any unused tickets will be forfeited and are not transferable to another event.</p>
                <p>TICKET HOLDERS MAY NOT SELL, OFFER FOR SALE, OFFER AT AUCTIONS, RESELL, DONATE, ACT AS COMMERCIAL AGENT FOR ANOTHER PARTY OR OTHERWISE TRANSFER THEIR TICKETS IN ANY WAY WITHOUT THE SPECIFIC PRIOR WRITTEN CONSENT OF DIGITALMARKETER.</p>
                <p className="Body"><strong>7.1 Ticket Transfers</strong></p>
                <p>Ticket transfers will not be processed THIRTY (30) Days prior to the event.</p>
                <p>A $50 transfer fee will be applied to all ticket transfers.</p>
                <p><strong>7.2 Process for Guest Tickets Transfers</strong></p>
                <p className="Body">Once a Ticket purchase is confirmed, the Ticket Purchaser may only change the assignment of the Guests accompanying him a maximum of 2 times and using the Tickets that he purchased by:</p>
                <ul>
                    <li>Submitting a Guest Ticket Transfer Request: the Ticket Purchaser must submit a transfer request to support@{websiteAddress
                    }.com.</li>
                </ul>
                <p><strong>7.3 Ticket Resales</strong></p>
                <p>If a Ticket Purchaser cannot use all of the Tickets that he has purchased, the Ticket Purchaser will have the opportunity to submit to DIGTALMARKETER a formal request for the resale of the Tickets he no longer wants via the {websiteNameEx} support team, so that the support team can try to resell the Tickets to another attendee. Unlike the Guest Transfer policy, the Ticket Purchaser will not have to provide a reason for the Ticket Resale request.</p>
                <p>There is no guarantee that demand for Tickets will result in the Tickets being resold. If the Ticket Purchaser’s Tickets are not resold, no resale proceeds will be returned to the Ticket Purchaser.</p>
                <p className="Body"><strong>8. Live Streams</strong></p>
                <ul className="policylist">
                    <li>Refunds will only be provided in situations where the customer is unable to watch the Livestream due to technical issues.</li>
                    <li>Refunds will only be considered and processed on the first day of each respective event.</li>
                </ul> */}
                {/* <p className="Body">LIMITATION ON LIABILITY.</p>
                <p>THE {websiteNameEx} STREAM AND ALL CONTENT AND SOFTWARE ASSOCIATED THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE {websiteNameEx} LIVE STREAM, ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. {websiteNameEx} DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE {websiteNameEx} LIVE STREAM WILL BE UNINTERRUPTED OR ERROR-FREE.</p>
                <p className="Body">{websiteNameEx} is not responsible for any problems or technical malfunction of any network or lines, computer online systems, servers, or providers, computer equipment, software, failure of any e-mail or redemption to be received by {websiteNameEx} on account of technical problems or traffic congestion online or on the Internet or at any Web site, or any combination thereof including any injury or damage to customer’s or any other person’s computer related to or resulting from downloading or streaming any materials consistent with this agreement or subsequent use of any {websiteNameEx} owned web properties. If, for any reason, the stream is not capable of running online as planned, including infection by computer virus, bugs, tampering, unauthorized intervention, fraud, technical failures, or any other causes beyond the control of {websiteNameEx} which threatens or corrupts or adversely affects the administration, security, fairness, integrity or proper conduct of a live stream offer, {websiteNameEx}, reserves the right, in its sole discretion, to cancel, terminate or suspend the offer and/or any subscription. ANY ATTEMPT BY YOU TO DELIBERATELY DAMAGE ANY WEB SITE OR UNDERMINE THE LEGITIMATE OPERATION OF THIS OFFER MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS AND SHOULD SUCH AN ATTEMPT BE MADE, {websiteNameEx} WILL DISQUALIFY YOUR ATTEMPTED REDEMPTION AND RESERVES THE RIGHT TO SEEK DAMAGES FROM YOU TO THE FULLEST EXTENT PERMITTED BY LAW.</p> */}
                <p className="Body"><strong>9. Your Account</strong></p>
                <p className="Body">You agree to provide accurate and complete information when you register with, and as you use, the {websiteNameEx}, and you agree to update your account information to keep it accurate and complete. You agree that {websiteNameEx} may store and use the information you provide for use in maintaining and billing fees to your Account.</p>
                {/* <p className="Body"><strong>9.1 Access to Previous Purchases</strong></p>
                <p className="Body">As an accommodation to you, subsequent to acquiring {websiteNameEx} training, you may download previously acquired training (when available) onto any Associated Device. Some {websiteNameEx} training or services that you previously acquired may not be available for subsequent download or access at any given time, and {websiteNameEx} shall have no liability to you in such event. As you may not be able to subsequently download certain previously-acquired content or training, once you download an item, it is your responsibility not to lose, destroy, or damage it, and you may want to back it up.</p> */}
                {/* <p className="Body">{websiteNameEx} reserves the right to change content options (including eligibility for particular features) or remove access without notice to any previously purchased training or content that is no longer valid or deemed out of date.</p> */}
                {/* <p className="Body"><strong>10. Social Media Groups and Communities</strong></p>
                <p>Business owners&nbsp; of all levels are welcome here. We have community members of all skill and experience levels, from college students to entrepreneurs to multi-million dollar enterprises.</p>
                <p>We want people to feel safe when using our groups and forums. For that reason, we’ve developed a set of community guidelines, outlined below. These policies will help you understand what type of sharing is allowed in our private groups and communities, and what type of content may be reported to us and removed. Because of the diversity of our global community, please keep in mind that something that may be disagreeable or disturbing to you may not violate our community guidelines.</p>
                <ul>
                    <li className="Body"><strong>Business owners of all levels are welcome here.</strong><br />We have members of all skill and experience levels, from college students to entrepreneurs to multi-million dollar enterprises.</li>
                    <li className="Body"><strong>Keep it respectful</strong><br />Comments that are inappropriately negative, rude, or attacking will be deleted, removed, or we will ask you to revise your thoughts. Everyone is here to learn and grow, so anything violating that will be removed.</li>
                    <li className="Body"><strong>No pitching to the group</strong><br />We have a strict ‘no shopping for customers’ policy in our groups. This includes pointing people to blog posts with your offers/affiliate offers and publicly asking members to join your own Facebook groups or communities. Be cautious of unsolicited private messages to group members. If we get multiple complaints that you or someone else is using private messages to make unsolicited pitches to members, you may be asked to leave. You should view the group as your peers, not your leads.</li>
                    <li className="Body"><strong>No gated content</strong><br />Content posted in the groups cannot be used to harvest leads in any way. If you need a feedback or a review, post the direct PDF, document, or screenshot. Don’t require people to opt-in to view.</li>
                    <li className="Body"><strong>Respect confidentiality</strong><br />Content gathered in our online community groups cannot be collected, repackaged, and/or shared outside the group. Every member of our communities has a right to privacy and the right to feel safe that their questions, answers, and experiences remain privy to group members only.</li>
                    <li className="Body"><strong>Keep it on topic</strong><br />We reserve the right to remove posts based on off-topic content or offensive content.</li>
                    <li className="Body"><strong>Partnering with other members</strong><br />Access to this group does not mean that {websiteNameEx} endorses anyone’s products or services. Please be cautious and do your due diligence when partnering with anyone in the group – don’t assume everyone is trustworthy. If you are looking for help implementing the stuff that {websiteNameEx} teaches, we do have a list of (vetted) partners through our Certified Partner program. You can check them out here:&nbsp;<a href="https://www.{websiteNameEx}.com/certified-partners">www.{websiteNameEx}.com/certified-partners</a></li>
                    <li className="Body"><strong>Report posts that are breaking group policies</strong><br />Due to the large amount of people in our groups and communities, it’s sometimes difficult for us to catch everything. If you see a post that is questionable, please report it, tag our community manager in the comments, or reach out to our community manger directly via private message so our team can review them.</li>
                    <li className="Body"><strong>Breaking the rules</strong><br />Three warnings for rule-breaking behavior will result in a 7-day removal from the group. After the 7 day period, you are welcome to rejoin the group on a 30-day probation. If you break the rules again during your probation, you will be permanently removed from the community or group.</li>
                </ul> */}
                <p className="Body"><strong>11. INTELLECTUAL PROPERTY</strong></p>
                <p>You agree that the {companyName}, including but not limited to {websiteNameEx} Products and services, graphics, user interface, audio clips, video clips, editorial content, templates and the scripts and software used to implement {websiteAddress}, contains proprietary information and material that is owned by {websiteNameEx} and/or its licensors, and is protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary information or materials in any way whatsoever except for use of the {websiteNameEx} in compliance with this Agreement. No portion of the {websiteNameEx} may be reproduced in any form or by any means, without expressed written permission from {websiteNameEx}. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the {websiteNameEx} in any manner, and you shall not exploit the {websiteNameEx} brand in any unauthorized way whatsoever, including, but not limited to, by trespass or burdening network capacity.</p>
                <p>Notwithstanding any other provision of this Agreement, {websiteNameEx} and its licensors reserve the right to change, suspend, remove, or disable access to any {websiteNameEx} products, content, or other materials comprising a part of the {websiteNameEx} brand at any time without notice. In no event will {websiteNameEx} be liable for making these changes. {websiteNameEx} may also impose limits on the use of or access to certain features or portions of {websiteNameEx}, in any case and without notice or liability.</p>
                <p>All copyrights in and to {companyName} (including the compilation of content, postings, links to other Internet resources, and descriptions of those resources) and related software are owned by {websiteNameEx} and/or its licensors, who reserve all their rights in law and equity. THE USE OF THE SOFTWARE OR ANY PART OF {companyName}, EXCEPT FOR USE OF {companyName} AS PERMITTED IN THIS AGREEMENT, IS STRICTLY PROHIBITED AND INFRINGES ON THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS AND MAY SUBJECT YOU TO CIVIL AND CRIMINAL PENALTIES, INCLUDING POSSIBLE MONETARY DAMAGES, FOR COPYRIGHT INFRINGEMENT.</p>
                <p className="Body">{websiteNameEx}, the {websiteNameEx} logo, other {websiteNameEx} trademarks, service marks, graphics, and logos used in connection with {companyName} are trademarks or registered trademarks of {websiteNameEx} in the U.S. and/or other countries. Other trademarks, service marks, graphics, and logos used in connection with {websiteNameEx} may be the trademarks of their respective owners. You are granted no right or license with respect to any of the aforesaid trademarks and any use of such trademarks.</p>
                {/* <p><strong>Personal Checks</strong></p>
                <ul>
                    <li>All checks are held for 10 business days. Orders placed with a check will be held for 10 business days. Once 10 business days is complete, the order will be released and shipped.</li>
                    <li>All returned check will be charged a $25 USD return check fee.</li>
                    <li>Counter checks will not be accepted.</li>
                    <li>Checks and money orders must be sent in US funds.</li>
                </ul> */}
                <p><strong>Customer Service:</strong></p>
                <p>If you have questions or comments regarding {companyName} products, please email us at&nbsp;<a href={`mailto:support@${websiteAddress}.com`}>support@{websiteAddress}.com</a>&nbsp;or call 470-595-5674.</p>
            </section>
        </main>
    )
}

export default TermsPage;