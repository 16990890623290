import React, { useState, useEffect } from 'react'
import { client } from '../client'
import { useNavigate, useParams } from 'react-router-dom'
import CircularProgress from '../CircularProgress/CircularProgress';
import { checkPassword } from '../../util/utilFunctions';
// import logo from '../../assets/imgs/logo.jpg'

const ResetPasswordPage = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [pError, setPError] = useState("");
    const [loading, setLoading] = useState(true);
    const [passwordReset, setPasswordReset] = useState(false);

    const { passwordResetId } = useParams();
    let navigate = useNavigate()

    useEffect(() => {
        onPageLoad()
    }, [])

    const onPageLoad = function () {
        // Verifies authorization code from email
        client.post('/api/auth/authorize-reset-password', { passwordResetId: passwordResetId }, { withCredentials: true })
            .then(res => {
                if (res.status === 200) {
                    setLoading(false);
                }
            })
            .catch(err => {
                if (err.response.data.message.includes("No such user exists")) {
                    setPError("Try resetting your password again");
                } else {
                    setPError("Invalid request")
                }
            })
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if(password !== confirmPassword) return setPError("Passwords do not match")

        if (checkPassword(password, setPError)) setPError('')
        else return

        client.patch('/api/auth/complete-reset-password', { password, passwordResetId }, { withCredentials: true })
            .then(res => {
                if (res.status === 200) {
                    setPasswordReset(true);
                }
            })
            .catch(err => {
                if (err.response.data.message.includes("No such user exists")) {
                    setPError("Try resetting your password again");
                } else {
                    setPError("Invalid request")
                }

                setLoading(false);
            })
    }

    const renderAuth = (loadStatus: boolean) => {
        if(passwordReset) return (
            <>
                <p>Password has been reset</p>
                <button className="main-button" onClick={() =>  navigate("/login")}>
                Login
                </button>
            </>
        )

        if (loadStatus) {
            return <CircularProgress />
        } else {
            return (
                <>
                    <div className="input-container">
                        <label className="required">Password</label>
                        <input
                            placeholder="password"
                            required
                            id="login-password"
                            value={password}
                            type="password"
                            onChange={e => {
                                if (pError) setPError("");
                                setPassword(e.target.value)
                            }}
                        />
                    </div>
                    <div className="input-container">
                        <label className="required">Confirm Password</label>
                        <input
                            placeholder="password"
                            required
                            id="confirm-password"
                            value={confirmPassword}
                            type="password"
                            onChange={e => {
                                if (pError) setPError("");
                                setConfirmPassword(e.target.value)
                            }}
                        />
                    </div>
                    <button className="main-button" type="submit">Next</button>
                </>
            )
        }
    }

    return (
        <main>
            <div className="auth flex-center">
                <div className="form-container mini">
                    <form onSubmit={onSubmit} className="auth-form">
                        <div className="error-msg">
                            {!!pError ? <p className="error-output">{pError}</p> : ''}
                        </div>
                        <h1 className="auth-header" style={{ fontSize: '2.4rem', fontWeight: 'bold' }} >Reset Password</h1>
                        {renderAuth(loading)}
                    </form>
                </div>
            </div>
        </main>
    )
}

export default ResetPasswordPage