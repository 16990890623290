export const getUser = (accessKey: string) => {
    const user = localStorage.getItem(accessKey)
    if(!user) return null
    return user
}

export const setUser = (accessKey: string, data: string) => {
    localStorage.setItem(accessKey, data)
}

export const removeUser = (accessKey: string) => {
    localStorage.removeItem(accessKey)
}