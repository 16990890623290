import { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { INavbar, User } from "../util/customTypes"
import { navPaths, websiteName } from "../util/constants"
import LavaLampNav from "./LavaLampNav"

const Navbar: React.FC<INavbar> = ({ user }) => {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const navigate = useNavigate();

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const toggleMobileNav = (currentState: boolean) => setMobileNavOpen(!currentState);

    const mobileMenu = (
        <>
            <div className="top-nav-section">
                <NavLink onClick={() => mobileNavOpen ? toggleMobileNav(mobileNavOpen) : null} to="/">
                    <div className="logo-container">
                        {websiteName}
                    </div>
                </NavLink>
                <button onClick={() => navigate("/contact")} className="cta-button">
                    CONTACT US
                </button>
                <div
                    className={mobileNavOpen ? "mobile-menu-button open" : "mobile-menu-button"}
                    role="button"
                    onClick={() => toggleMobileNav(mobileNavOpen)}>
                    <div className="menu-btn_burger"></div>
                </div>
            </div>
            <ul className={mobileNavOpen ? "mobile-menu mobile-toggle" : "mobile-menu"}>
                {navPaths.map((navPath, i) => (
                    <li key={i} className="link-item ">
                        <NavLink
                            className=""
                            onClick={() => toggleMobileNav(mobileNavOpen)}
                            to={navPath.path}>
                            {navPath.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </>
    )

    const desktopMenu = ( <LavaLampNav navPaths={navPaths} /> )

    return (
        <nav className="main-nav">
            { isMobile ? mobileMenu : desktopMenu }
        </nav>
    )
}

export default Navbar