import pineginga from "../assets/imgs/pineginga.png";
import growth from "../assets/imgs/growth.png";
import optimization from "../assets/imgs/optimization.png";

export const websiteName = "Descifle Consulting";
export const emailName = "info@descifle.com";
export const siteConsult = "https://smashdom.ue.r.appspot.com/";

export const defaultUser = { admin: false, id: "0", username: "defaultUser", email: "defaultUser@gmail.com", authorized: false };

export const navPaths = [
    {
        path: "/",
        name: "Home"
    },
    {
        path: "contact",
        name: "Contact"
    }
];

export const projectTypes = [
    "Select an option", "Mobile App", "Website", "Web to App", "SEO", "Other"
];

export const heardAboutUsWays = ["Select an option", "Search Engine (Google, Bing, etc.)", 
"Social Media (LinkedIn, Twitter, Glassdoor etc.)", "Recommended by a friend or colleague", "Other"];

export const spotlights = [
    {
        spotlightImage: pineginga,
        spotlightImageText: "",
        spotlightHeader: "PINE & GINGA",
        spotlightMessage: "A fruit juice company located in Canada.",
        spotlightLink: "https://www.google.com",
        spotlightLinkText: "A link to google"
    },
    // {
    //     spotlightImage: "",
    //     spotlightImageText: "",
    //     spotlightHeader: "Jamaican Domino",
    //     spotlightMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //     spotlightLink: "https://www.google.com",
    //     spotlightLinkText: "A link to google"
    // }
];

export const serviceHighlights = [
    {
        highlightImage: optimization,
        highlightHeader: "Improve Acquisition",
        highlightMessage: "Investing in SEO-friendly design and content optimization helps your website rank higher in search engine results, driving more organic traffic to your site.",
    },
    {
        highlightImage: growth,
        highlightHeader: "Achieve Growth",
        highlightMessage: `In today’s digital age, having a solid online presence is crucial for businesses of all sizes. 
        It allows you to reach a wider audience and connect with potential customers. By establishing your brand online, 
        you can increase visibility and credibility in the eyes of consumers. (break)
        An updated website that is user-friendly and optimized for search engines 
        can significantly boost your online presence. A fast-loading website with relevant content
         helps improve user experience and encourages visitors to stay longer on your site.`,
    }
]