import { useAuth } from "../hooks/useAuth";
import { defaultUser } from "../util/constants";
import Footer from "./Footer";
import Navbar from "./Navbar"
import { Outlet } from "react-router-dom";


const RootElement = () => {
    const {user} = useAuth();

    return (
        <>
            <Navbar user={user} /> 
            <Outlet />
            <Footer />
        </>
    )
}

export default RootElement;