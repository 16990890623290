import { useState } from "react";
import { siteConsult, projectTypes as topics } from "../util/constants";
import { heardAboutUsWays as leads } from "../util/constants";
import ArrowSVG from "./svgs/ArrowSVG";
import { onItemBlur, onItemFocus, validateEmail } from "../util/utilFunctions";
import { client } from "./client";

export default () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [errors, setErrors] = useState({ name:"", phone:"", email:"", company:"", message:"" });
    const [topic, setTopic] = useState(topics[0]);
    const [leadPath, setLeadPath] = useState(leads[0]);
    const [message, setMessage] = useState("");

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        let success = false;

        let tempErrors = errors;

        const phoneTrimmed = phone.replace(/-|\s/, "")

        if(phone.length !== 0 && phoneTrimmed.length !== 10) {
            tempErrors.phone = "Phone number is not valid";
            success = false;
        }
        else if(name.length === 0) {
            tempErrors.name = "Enter a name";
            success = false;
        }
        else if(!validateEmail(email)) {
            tempErrors.email = "Please enter a valid email";
            success = false;
        }
        else if(businessName.length !== 0 && businessName.length < 2) {
            tempErrors.company = "Please enter a valid company name";
            success = false;
        }
        else if(message.length === 0) {
            tempErrors.message = "Please add project details";
            success = false;
        }
        else success = true;

        setErrors({ ...tempErrors });

        console.log(success, errors)    

        if(success) {
            console.log(success)
            // store information somewhere
            client.post(siteConsult, { name, email, phone, business: businessName, topic, lead: leadPath, projectDetails: message })
            .then(res => {
                console.log(res.data.message)
                if(res.status === 200) {
                    // show success message
                    return;
                }

                // show error message
            })
            .catch(e => {
                // show error message
            });
        }
    }

    return (
        <main className="contact">
            <h1>Contact Us</h1>
            <p>
                Thank you for reaching out. We'll make sure that our team receives your message!
            </p>
            <hr />
            <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="multi-input">
                    <div className="input-container">
                        <label className="required">Name</label>
                        <input
                            required
                            className={errors.name ? "error-field" : ""}
                            type="text"
                            placeholder="Peter Johnson"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <small className={errors.name !== "" ? "error-info" : "hidden"}>{errors.name}</small>
                    </div>
                    <div className="input-container">
                        <label className="required">Email</label>
                        <input
                            required
                            type="email"
                            placeholder="peter@email.com"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onFocus={e => onItemFocus(e, setErrors, { ...errors, email: "" })}
                            onBlur={e => onItemBlur(e, setErrors, { ...errors, email: "" })}
                        />
                        <small className={errors.email !== "" ? "error-info" : "hidden"}>{errors.email}</small>
                    </div>
                </div>
                <div className="multi-input">
                    <div className="input-container">
                        <label>Phone Number</label>
                        <input
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{3}[0-9]{3}[0-9]{4}"
                            placeholder="XXX XXX XXXX"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            onFocus={e => onItemFocus(e, setErrors, { ...errors, phone: "" })}
                            onBlur={e => onItemBlur(e, setErrors, { ...errors, phone: "" })}
                        />
                        <small className={errors.phone !== "" ? "error-info" : "hidden"}>{errors.phone}</small>
                    </div>
                </div>
                <div className="multi-input">
                    <div className="input-container">
                        <label>Company</label>
                        <input
                            type="text"
                            placeholder="company name"
                            value={businessName}
                            onChange={e => setBusinessName(e.target.value)}
                            onFocus={e => onItemFocus(e, setErrors, { ...errors, company: "" })}
                            onBlur={e => onItemBlur(e, setErrors, { ...errors, company: "" })}
                        />
                        <small className={errors.company !== "" ? "error-info" : "hidden"}>{errors.company}</small>
                    </div>
                </div>
                <div className="input-container">
                    <label>Topic</label>
                    <select defaultValue={topic} onChange={e => setTopic}>
                        {topics.map((topic, i) => {
                            return <option value={topic} key={i}>{topic}</option>
                        })}
                    </select>
                </div>
                <div className="input-container">
                    <label>How did you hear about us</label>
                    <select defaultValue={leadPath} onChange={e => setLeadPath}>
                        {leads.map((lead, i) => {
                            return <option value={lead} key={i}>{lead}</option>
                        })}
                    </select>
                </div>
                <div className="input-container">
                    <label className="required">Project details</label>
                    <textarea
                        cols={30}
                        rows={10}
                        required
                        placeholder="Project Details"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    >
                    </textarea>
                </div>
                <button className="main-button" type="submit"><ArrowSVG /> Send Message</button>
            </form>
        <div className="m-top-lg"></div>
        </main>
    )
}