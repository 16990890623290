import Navbar from "./../Navbar"
import { useLocation, Navigate, useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth"
import Footer from "../Footer";
import { useEffect, useState } from "react";
import { client } from "../client";
import CircularProgress from "../CircularProgress/CircularProgress";


const RequireAuthElement = () => {
    const { user, signIn, signOut } = useAuth();

    const [loading, setLoading] = useState(user.authorized);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.authorized) {
            // possibly avoid needless verifies and only check after a while
            client.get("/api/auth/verify")
                .then((res) => {
                    if (res.status === 200 && res.data.message === "user is authorized") {
                        if (user.authorized === false) {
                            let loggedInUser = res.data.user;
                            loggedInUser.authorized = true;
                            signIn(loggedInUser)
                        }

                        setLoading(false);

                        return;
                    } else {
                        signOut(user);
                        navigate("/login");
                    }

                    setLoading(false);
                })
                .catch(err => {
                    signOut(user);
                    navigate("/login");
                    setLoading(false);
                });
        }
    })

    const View = () => {
        return <>
            <Navbar user={user} />
            <Outlet />
            <Footer />
        </>
    }

    return (
        <>
            {
                loading ? <CircularProgress /> : user.authorized ? <View /> : <Navigate to="/login" state={{ from: location }} replace />
            }
        </>
    )
}

export default RequireAuthElement;