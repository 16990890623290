import { Link } from "react-router-dom";
import { emailName, websiteName } from "../util/constants";
import Logo from "./svgs/logoSVG";

const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer-logo">
                <h4><Logo />{websiteName.substring(1, websiteName.length)}</h4>
            </div>
            <div className="footer-navs">
                <div className="footer-nav">
                    <h2>About Us</h2>
                    <ul>
                        <li>About</li>
                        <li>FAQ</li>
                    </ul>
                </div>
                <div className="footer-nav">
                    <h2>Contact Us</h2>
                    <ul>
                        <li>Telephone: 470-595-5674</li>
                    </ul>
                </div>
            </div>
            <div className="terms">
                <ul>
                    <li>
                        <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to="/terms">Terms of Use</Link>
                    </li>
                </ul>
            </div>
            <div className="copy">
                <small>&copy; {new Date().getFullYear()} Descifle LLC</small>
            </div>
        </footer>
    )
}

export default Footer;