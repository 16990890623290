import { NavLink, useNavigate } from "react-router-dom"
import { serviceHighlights, spotlights, websiteName } from "../util/constants";
import ArrowSVG from "./svgs/ArrowSVG";
import skillsImg from "../assets/imgs/services-skills.png";
import deskToMobile from "../assets/imgs/desktop_to_mobile.png";
import Carousel from "./Carousel/Carousel";

const HomePage = () => {

    const navigate = useNavigate();

    const content = [
        {
            title: "Web Development", description: "Develop an innovative next generation website with our professional Web Developers.",
            image: skillsImg
        },
        {
            title: "Convert Web Into App", description: "Want to increase your brand presence and boost sales, Let's convert your website into a Mobile App.",
            image: deskToMobile
        },
        //     { title: "App Development", description: "Develop an innovative next generation app with our expert React Native App Development team.",
        //     image: skillsImg
        // }
    ]

    return (
        <main>
            <section className="intro">
                <h1>Disciples of Tech</h1>
                <hr />
                <p>
                    {websiteName} is in the business of advising and building businesses.
                </p>
                <p>With our Disciplined approach, we design modern and innovative solutions leveraging strategy, implementation, and digital services for small to Fortune 500 companies.</p>
            </section>
            <section className="services">
                <div className="flex-between">
                    <Carousel children={content} />
                    {/* carousel of services in mobile have a cta to contact us -> contact us page has a form and a number if they want to give a direct call */}
                    {/* <ShopifySVG />
                    <WordpressSVG />
                    <ReactSVG /> */}
                    {/* <SEOSVG /> */}
                </div>
            </section>
            <section className="container">
                <h2>Discover why Focus Fuels Growth</h2>
                <p>
                    A honed website brings about success by acting as a front for your digital presence.
                    Conglomerating your marketing from email marketing, and social media.
                    Allowing for a more streamlined and effective marketing effort
                </p>
                <div className="highlights">
                    {serviceHighlights.map((highlight, i) => {
                        const highlightMessages = highlight.highlightMessage.split("(break)");

                        return (
                            <div className="highlight" key={i}>
                                <div className="highlight-header">
                                    <h4>{highlight.highlightHeader}</h4>
                                    <img src={highlight.highlightImage} />
                                </div>
                                <div className="highlight-messages">
                                    {highlightMessages.map((message, i) => {
                                        return (
                                            <p key={i} className="highlight-message">
                                                {message}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            <section>
                <h2>Spotlight</h2>
                <div className="spotlights">
                    {spotlights.map((spotlight, i) => {
                        return (
                            <div className="spotlight" key={i}>
                                <div className="spotlight-image">
                                    <img src={spotlight.spotlightImage} alt={spotlight.spotlightImageText} />
                                </div>
                                <div className="spotlight-body">
                                    <h3 className="spotlight-header">
                                        {spotlight.spotlightHeader}
                                    </h3>
                                    <p className="spotlight-message">
                                        {spotlight.spotlightMessage}
                                    </p>
                                </div>
                                <a className="spotlight-link" href={spotlight.spotlightLink}><ArrowSVG /></a>
                            </div>
                        )
                    })}
                </div>
            </section>
            <section>
                <h3>Sister Companies</h3>
                <p>
                    Descifle is proud to nurture its entrepeneurial core through our various sister companies.
                    We work with adjacent businesses to optimize our areas of expertise. We address needs we see in the marketplace to
                    drive additional value to our clients.
                </p>
            </section>
            <section>
                <h2>Our Values</h2>
                <div>
                    <div>
                        <h3>People First</h3>
                        <p>
                            Your company's needs are our priority, and we are here to listen, support, and provide the best guidance to help it thrive.
                        </p>
                    </div>
                    <div>
                        <h3>Advance Together</h3>
                        <p>
                            Advancement is a journey and we support you with every step forward.
                        </p>
                    </div>
                </div>
            </section>
            <section className="outro flex-center column m-top-lg">
                <button onClick={() => navigate("/contact")} className="cta-button" style={{ margin: "auto" }}>Contact Us</button>
                <h2 className="text-center m-top-lg">Take the First Step in Your Journey with {websiteName}</h2>
                <p className="m-top-lg">Ready to get started?</p>
                <button onClick={() => navigate("/contact")} className="main-button m-top-lg">Let's Talk <ArrowSVG /></button>
                {/* <div>
                    <div>
                        <h3>People First</h3>
                        <p>
                        Your company's needs are our priority, and we are here to listen, support, and provide the best guidance to help it thrive.
                        </p>
                    </div>
                    <div>
                        <h3>Advance Together</h3>
                        <p>
                        Advancement is a journey and we support you with every step forward.
                        </p>
                    </div>
                </div> */}
            </section>
        </main>
    )
}

export default HomePage;