import { Link, useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "../CircularProgress/CircularProgress";
import { websiteName } from "../../util/constants";
import { client } from "../client";
import { useState } from "react";

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [successfulReset, setSuccessfulReset] = useState(false);

    let location = useLocation();
    let navigate = useNavigate();

    const checkEmail = () => {
        if (email.length >= 50) {
            setError('email is too long');
            const input = document.querySelector("#login-email") as HTMLElement;
            input.focus();

            return false;
        } else {
            setError('');
            return true;
        }
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (checkEmail()) setError('')
        else return

        setLoading(true)

        client.post('/api/auth/reset-password', { email: email })
            .then(res => {
                if (res.status === 200) {
                    // after getting resetpasswordid go to page that will accept resetpasswordid
                    setSuccessfulReset(true);
                }
            })
            .catch(err => {
                console.log(err.response)

                if (err.response) {
                    if (err.response.status === 500) return alert("Server error: There is something wrong on our side. Please provide some time to restore the servers")

                    if (err?.response.data.message.includes("Possibly missing login information")) {
                        return setError("That user is not registered")
                    } else if (err?.response.data.message.includes("Incorrect login information")) {
                        return setError("That user is not registered");
                    }
                }

                return setError("There was an error proccessing your request")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderLoginScreenContent = (loading: boolean) => {
        if (loading) {
            return <CircularProgress />
        } else {
            return (
                <div className="form-container mini">
                    <form onSubmit={onSubmit} className="auth-form">
                        {!!error ? <p className="error-output">{error}</p> : ''}
                        {/* <img alt="logo" src={logo} /> */}
                        <h1 className="auth-header" style={{ fontSize: '2.4rem', fontWeight: 'bold' }}>Forgot Password</h1>
                        {
                            successfulReset === false ? (
                                <>
                                    <div className="input-container">
                                        <label className="required">Email</label>
                                        <input
                                            placeholder="example@mail.com"
                                            required
                                            id="login-email"
                                            type="email"
                                            value={email}
                                            onChange={e => {
                                                if (error) setError("");
                                                setEmail(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <p>
                                        We'll send a verification code to this email if it matches an existing {websiteName} account.
                                    </p>
                                    <button className="main-button" type="submit">Next</button>
                                </>
                            ) :
                                (
                                    <p>An email was sent to the email address provided with the reset link.</p>
                                )
                        }
                        <Link className="optional-button" to="/login">Back</Link>
                    </form>
                </div>
            )
        }
    }

    return (
        <main>
            <div className="auth flex-center">
                {renderLoginScreenContent(loading)}
            </div>
        </main>
    )
}

export default ForgotPasswordPage;