import { useState, useContext } from "react";
import { client } from "../components/client";
import { AuthContext } from "../contexts/AuthContext";
import { defaultUser } from "../util/constants";
import { IComponentProvider, User } from "../util/customTypes";

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<IComponentProvider> = ({ children }) => {
    const authFeatures = useAuthHandler();

    return (
        <AuthContext.Provider value={authFeatures}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuthHandler = () => {
    const [user, setUser] = useState(defaultUser);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const signIn = (user: User) => {
        console.log(user)
        setUser(user);
    }

    const signOut = () => {
        client.delete("/api/auth/logout")
        .then((res) => {
            if (res.status === 200) {
                setUser(defaultUser);
            }
        })
        .catch(err => {
            setErrors(err);
        })
    }

    return {
        user,
        errors,
        isLoading,
        signIn,
        signOut
    }
}

